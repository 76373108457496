/* HTML Elements */
body {
    background-color: var(--background);
    min-height: 100vh;
    font-family: 'Poppins', sans-serif !important;
    font-size: 14px !important;
    line-height: 150%;
    color:var(--primary)
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0px;
    margin: 0px;
}

p {
    margin-bottom: 0px;
}

h1{
    font-size: 36px;
    font-weight: var(--fontMed);
    text-align: center;
}
h2{
    font-size: 32px;
    font-weight: var(--fontMed);
}
h3{
    font-size: 24px;
    font-weight: var(--fontMed);
}
h4{
    font-size: 20px;
    font-weight: var(--fontSemi);
}
h6{
    font-size: 18px;
    font-weight: var(--fontSemi);
}
label{
    font-size: 14px;
    font-weight: var(--fontReg);
    color: var(--secondary);
}
footer{
    background-color: var(--background);
    padding: 10px;
    height: 50px;
}
a{
    color: var(--primary);
    text-decoration: none;
}
a:hover{
    color: var(--secondary);
}
sup{
    color:var(--error)
}
/* Helpers */
.showMobi{
    display: none !important;
}
.redText{
    color:var(--error)
}
.showMobi{
    display: none;
}
.pointer{
    cursor: pointer;
}
.W100{
    width: 100px;
}
.W250{
    width: 200px;
}
.W300{
    width: 300px;
}
.W350{
    width: 350px;
}
.W400{
    width: 400px;
}
.W450{
    width: 450px;
}
.W550{
    width: 550px;
}
.W600{
    width: 650px;
}
.maxHeight{
    min-height: 100vh;
}
.fullHeight {
    min-height: 80vh;
}
.mainContent{
    background-color: var(--white);
    min-height: 80vh;
    margin-bottom: 24px;
}
/* Top Nav */
.nav-link{
    color: var(--primary);
    font-weight: var(--fontReg);
    font-size: 16px;
}
.nav-link:hover{
    color: var(--yellow);
    font-weight: var(--fontReg);
    font-size: 16px;
}
.site-header {
    box-shadow: 0 0 8px 2px rgba(253, 247, 229, 0.1);
    padding: 16px 0;
    min-height: 80px;
}
a.headerBtn{
    color: var(--primary);
    border-radius: 10px;
    padding: 16px 24px;
    font-weight: var(--fontMed);
    border: 1px solid var(--primary);
}
.headerBtn:hover{
    color: var(--white);
    background-color:var(--primary);
}
/* home */
.homeBox {
    border-radius: 8px;
    background-color: var(--white);
    padding: 20px 40px;
    width: 475px;
    margin: 25px 0px 25px 0px;
}


@media (max-width: 575px) {
    .mobiLeft{
        text-align: left !important;
        width: 100%;
        margin-left: 0.5rem;
    }
    .mainContent{
        margin-bottom: 0px;
    }
    .GiLocaleSelect{
        display:none !important
    }
    h1{
        font-size: 24px;
    }
    h4{
        font-size: 18px;
    }
    .mobiFull{
        width: 100%;
    }
    .mobiTop{
        margin-top: 25px;
    }
    .mobiMs{
        margin: 0px !important;
    }
    .mobiRow{
        flex-direction: column !important;
    }
    .mobiColumn{
        flex-direction: row !important;
    }
    .mobiSpacer{
        margin-top: 25px!important;
    }
    .maxHeight{
        min-height: 50vh;
    }
    footer{
        display: none;
    }
    .W350, .W400, .W450, .W550, .W600{
        width: 300px;
    }
    .mainContent, .fullHeight{
        min-height: 100vh;
    }
    .homeBox{
        width: 100%;
    }
    .mobiColumn{
        flex-direction: column !important;
    }
    .mobiColumnMargin{
        margin-top:28px;
    }
    .hideMobi{
        display: none !important;
    }
    .showMobi{
        display: flex !important;
    }
}

.terminalWrapper {
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
  }
  
  .listView {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .linkButton {
    position: relative;
    color: #1e053a;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 20px;
    background-color: #ffffff;
    padding: 12px 24px;
    border-radius: 10px;
    border: 1px solid #1e053a;
    cursor: pointer;
    margin-left: 50px;
    text-decoration: none;
  }
  
  .thankMsg {
    color: #1f6812;
    font-weight: 500;
    font-family: "Poppins";
    font-size: 16px;
    line-height: 20px;
    margin-left: 50px;
    padding: 12px 24px;
    border-radius: 10px;
    border: 1px solid #1f6812;
    ;
  }
  
  .landingWrapper {
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 20px;
  }
  
  p {
    color: #81768d;
  }
  
  .loadingWrapper {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #81768d;
    ;
    font-weight: 500;
    font-family: "Poppins";
    padding: 15px 20px;
  }
  
  .errorModalWrapper {
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    width: 180px;
    align-self: center;
    text-align: center;
    color: #1e053a;
    border-radius: 10px;
    box-shadow: 2px 4px 20px 4px #888888;
    padding-bottom: 16px;
    justify-content: center;
    align-items: flex-end;
    padding: 15px 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  
  .errorInner {
    text-align: center;
  }
  
  .errorInner h3 {
    font-size: 21px;
    line-height: 24px;
    font-weight: 500;
  }
  
  .errorInner p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }
  
  .closeButton {
    background-color: transparent;
    font-size: 24px;
    line-height: 36px;
    font-weight: 500;
    border: none;
    cursor: pointer;
  }