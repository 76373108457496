.form__group {
    width: 100%;
}

.form__radio-group {
    display: inline-block;
}

.form__radio-input {
    display: none;
}

.form__radio-label {
    cursor: pointer;
    position: relative;
}

.form__radio-button {
    height: 18px;
    width: 18px;
    border: 3px solid var(--grey);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    left: 0;
    top: 5px;
}

.form__radio-button::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--btnAct);
    opacity: 0;
    transition: opacity 0.5s;
}

.form__radio-input:checked+.form__radio-label .form__radio-button::after {
    opacity: 1;
}