:root {
    --primary: #1E053A;
    --secondary: #6E5F7F;
    --grey: #AAA2B2;
    --background: #FDF7E5;
    --lightBg: #FAFAFA;
    --white: #FFFFFF;
    --indigo: #6622DE;
    --error: #C2092B;
    --success: #4AB8B1;
    --yellow: #ffbc01;
    --extra: #EAE8EC;
    --btnAct: #4AB8B1;
    --btnDis: #AAA2B2;
    --txIn: #4AB8B1;
    --txOut: #C2092B;
    --fontReg: 400;
    --fontMed: 500;
    --fontSemi: 600;
    --fontBold: 700;
    --fontExtra: 800;
}